// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-maps-redistricting-timeline-alert-map-js": () => import("./../../../src/components/maps/RedistrictingTimelineAlertMap.js" /* webpackChunkName: "component---src-components-maps-redistricting-timeline-alert-map-js" */),
  "component---src-components-maps-reforms-map-js": () => import("./../../../src/components/maps/ReformsMap.js" /* webpackChunkName: "component---src-components-maps-reforms-map-js" */),
  "component---src-components-one-pager-layout-js": () => import("./../../../src/components/one-pager-layout.js" /* webpackChunkName: "component---src-components-one-pager-layout-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-mdx": () => import("./../../../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */),
  "component---src-pages-commissions-js": () => import("./../../../src/pages/commissions.js" /* webpackChunkName: "component---src-pages-commissions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-jsx": () => import("./../../../src/pages/info.jsx" /* webpackChunkName: "component---src-pages-info-jsx" */),
  "component---src-pages-redistricting-report-card-js": () => import("./../../../src/pages/redistricting-report-card.js" /* webpackChunkName: "component---src-pages-redistricting-report-card-js" */),
  "component---src-pages-redistricting-timeline-alert-map-js": () => import("./../../../src/pages/redistricting-timeline-alert-map.js" /* webpackChunkName: "component---src-pages-redistricting-timeline-alert-map-js" */),
  "component---src-pages-redistricting-timeline-js": () => import("./../../../src/pages/redistricting-timeline.js" /* webpackChunkName: "component---src-pages-redistricting-timeline-js" */),
  "component---src-pages-reforms-js": () => import("./../../../src/pages/reforms.js" /* webpackChunkName: "component---src-pages-reforms-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-tests-jsx": () => import("./../../../src/pages/tests.jsx" /* webpackChunkName: "component---src-pages-tests-jsx" */),
  "component---src-templates-og-image-one-page-js": () => import("./../../../src/templates/og-image-one-page.js" /* webpackChunkName: "component---src-templates-og-image-one-page-js" */),
  "component---src-templates-state-js": () => import("./../../../src/templates/state.js" /* webpackChunkName: "component---src-templates-state-js" */)
}

